:root {
    --app-height: 100%;
    --main-font: 'Raleway', 'Montserrat', 'Nunito Sans', 'Poppins', 'Tajawal', 'Mada', 'ui-sans-serif', 'system-ui';
    --second-font: 'Raleway', 'Montserrat', 'Nunito Sans', 'Poppins', 'Tajawal', 'Mada', 'ui-sans-serif', 'system-ui';
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-variant-numeric: lining-nums;
    /* background: #212326; */
}

body::-webkit-scrollbar {
    background: transparent;
}

body {
    scrollbar-color: transparent transparent;
}

body::-webkit-scrollbar-track {
    background: transparent;
}

body::-webkit-scrollbar-thumb {
    background-color: transparent;
}

#root {
    height: 100%;
}

.str-chat.str-chat-channel.messaging {
    background: white;
    /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06), 0px 2px 30px rgba(0, 0, 0, 0.1); */
}

.dark.str-chat.str-chat-channel.messaging {
    background: #212326;
    /* box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1); */
}

.str-chat.str-chat-channel-list.messaging {
    background: white;
}

.dark.str-chat.str-chat-channel-list.messaging {
    background: #212326 !important;
}

.str-chat-channel .str-chat__container {
    padding-top: 0px;
}

.str-chat-channel.messaging .str-chat__main-panel {
    padding: 0 !important;
    min-width: 250px;
}

.messaging.str-chat .str-chat__list .str-chat__reverse-infinite-scroll {
    padding-top: 0;
}

.messaging.str-chat.dark .str-chat__list {
    padding: 0 30px 0;
    background: #282a2d;
}

.str-chat-channel.messaging .str-chat__main-panel {
    padding: 30px 30px 0 0px;
}

.str-chat-channel.messaging .str-chat__main-panel:not(:last-child) {
    padding: 20px 0 0 0px;
}

.str-chat-channel.messaging .str-chat__main-panel:not(:last-child) .messaging__channel-header {
    border-radius: 10px 0px 0px 0px;
}

.str-chat__message-simple-status {
    display: none;
}

.messaging.str-chat.dark .str-chat__message--system__date {
    color: rgba(255, 255, 255, 0.7);
}

.messaging.str-chat.dark .str-chat__message--system__text p {
    color: rgba(255, 255, 255, 0.7);
}

.messaging.str-chat.dark .str-chat__message--system__line {
    background: rgba(255, 255, 255, 0.1);
}

.str-chat__message--system {
    padding: 20px;
}
.str-chat__list {
    /* height: calc(100%) !important; */
    height: auto !important;
}

/* .str-chat {
    height: calc(100vh - 32vw) !important;
} */
.str-chat {
    /* height: 100vh !important; */
    height: calc(100%) !important;
    /* height: calc(100% - 275px) !important; */
}

.str-chat-channel {
    max-height: 100vh !important;
    /* height: 80vh !important; */
}

/* Mobile View */
@media screen and (max-width: 640px) {
    /*
   * This is to prevent the browser navigation bars from hiding the app
   * on some mobile browsers. The app-height variable is set dynamically
   * using javascript.
   */
    .str-chat-channel {
        /* height: var(--app-height); */
        height: calc(100% - 32vw);
    }

    .str-chat-channel-list.messaging {
        float: unset;
    }

    #mobile-channel-list {
        width: 100vw;
        height: calc(100% - 32vw) !important;
        position: absolute;
        top: 0;
        left: 0;
        overflow-y: hidden;
        /* transform: translate3d(-100vw, 0, 0); */
        /* transition: transform 0.3s ease-in-out; */
        z-index: 100;
    }

    #mobile-channel-list.show {
        transform: translate3d(0vw, 0, 0);
    }
}

/* To fix inherited styles (as per Team and Customer Support apps */
@media screen and (max-width: 960px) {
    .str-chat-channel-list.messaging {
        position: unset;
        left: unset;
        top: unset;
        z-index: unset;
        min-height: unset;
        overflow-y: auto;
        box-shadow: unset;
        transition: unset;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    .str-chat-channel-list.messaging::-webkit-scrollbar {
        display: none;
    }
}

.str-chat__message-simple-text-inner {
    flex: initial;
    text-align: inherit;
    max-width: unset;
}

@media screen and (max-width: 960px) {
    .str-chat__message-simple-text-inner {
        max-width: unset;
    }
}

.str-chat__simple-message--error-message {
    text-align: left;
    text-transform: uppercase;
    font-size: 3vw;
    font-weight: 500;
    opacity: 0.5;
}

.str-chat__message--me.str-chat__message--error,
.str-chat__message--me.str-chat__message--failed,
.str-chat__message-simple--me.str-chat__message--error,
.str-chat__message-simple--me.str-chat__message--failed {
    border-left: initial;
    margin-right: 0;
}
.str-chat__message-simple {
    font-family: var(--second-font);
}
.str-chat__message--error,
.str-chat__message--failed,
.str-chat__message-simple--error,
.str-chat__message-simple--failed {
    margin: 0;
    font-size: 3vw;
    padding: var(--xxs-p) 0;
}

@media screen and (min-width: 640px) {
    .str-chat-channel .str-chat__container .str-chat__main-panel,
    .str-chat-channel .str-chat__container .str-chat-angular__main-panel-inner {
        max-height: calc(100vh - 80px);
        /* height: unset !important; */
        width: 100%;
        min-width: 250px;
        display: flex;
        flex-direction: column;
        flex: 1 1;
    }

    .str-chat.str-chat-channel-list.messaging {
        width: 500px;
    }

    .str-chat__ul {
        /* display: block;
        list-style-type: none;
        padding: 0; */
        margin: auto !important;
        max-width: 900px;
    }
}
