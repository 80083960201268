.channel-preview__container {
    min-height: 16vw;
    padding: 4vw 4vw;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.channel-preview__container:hover {
    background: #f3f4f6;
    /* box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07); */
    transition: background 0.1s ease-in-out;
}

.str-chat.dark .channel-preview__container:hover {
    background: #2c2c30;
}

.channel-preview__container.selected {
    background: #f3f4f6;
    /* box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07); */
    transition: background 0.1s ease-in-out;
}

.str-chat.dark .channel-preview__container.selected {
    background: #2c2c30;
}

.channel-preview__content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 4vw;
    margin-right: 4vw;
    width: 100%;
}

.channel-preview__content-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    height: 18px;
    margin-bottom: 4px;
}

.channel-preview__content-name {
    /* font-family: Helvetica Neue, sans-serif; */
    font-weight: 600;
    font-size: 4.4vw;
    color: #0000cc;
    margin: 0;
    max-width: 158px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.str-chat.dark .channel-preview__content-name {
    color: #ffffff;
}

.channel-preview__content-time {
    /* font-family: Helvetica Neue, sans-serif; */
    font-weight: 500;
    font-size: 3vw;
    color: #858688;
    margin: 0;
}

.channel-preview__content-message {
    font-family: Raleway, sans-serif;
    font-weight: 500;
    font-size: 3.5vw;
    line-height: 5vw;
    margin: 0;
    color: #858688;
    height: 5vw;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.channel-preview__container .str-chat__avatar {
    margin-right: 0;
}

.channel-preview__avatars {
    display: flex;
    align-items: center;
    height: 40px;
    min-width: 40px;
    max-width: 40px;
    border-radius: 20px;
    overflow: hidden;
}

.channel-preview__avatars.two div:first-child {
    position: relative;
    right: 10px;
}

.channel-preview__avatars.two div:nth-child(2) {
    position: relative;
    right: 30px;
}

.channel-preview__avatars.two span {
    width: 20px;
    overflow: hidden;
}

.channel-preview__avatars.three span {
    width: 20px;
    overflow: hidden;
}
