@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        -webkit-tap-highlight-color: transparent;
    }
    body {
        -webkit-touch-callout: none !important;
    }
    a {
        -webkit-user-select: none !important;
    }
}

@layer components {
    .otp-input {
        @apply w-16 !important;
    }
}

@layer utilities {
    .pb-safe {
        padding-bottom: env(safe-area-inset-bottom);
    }

    /* Chrome, Safari and Opera */

    .z-100 {
        z-index: 100 !important;
    }

    .z-200 {
        z-index: 200 !important;
    }

    .z-500 {
        z-index: 500 !important;
    }
    .z-900 {
        z-index: 900 !important;
    }
    .z-1000 {
        z-index: 1000 !important;
    }

    .no-scrollbar::-webkit-scrollbar {
        display: none !important;
    }

    .no-scrollbar {
        -ms-overflow-style: none !important; /* IE and Edge */
        scrollbar-width: none !important; /* Firefox */
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
}

@font-face {
    text-rendering: optimizeLegibility;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
::-webkit-scrollbar-thumb {
    background: #d1d1d1;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #969696;
}

::-webkit-scrollbar-track {
    background: transparent;
}

.static h1,
.static h2,
.static h3,
.static h4,
.static h5,
.static h6 {
    font-weight: 700;
    line-height: normal;
    margin-top: 5vw;
    color: rgb(0 0 204);
    font-size: 3vw;
}

.static h1 {
    font-size: 6.5vw;
    margin-top: 10vw;
}

.static hr + h1 {
    margin-top: 0vw;
}

.static h1:first-child,
.static h2:first-child,
.static h3:first-child,
.static p:first-child {
    margin-top: 10vw;
}

.static h2 {
    font-size: 4.5vw;
    /* margin-top: 10vw; */
}
.static h3 {
    font-size: 4vw;
}
.static h4 {
    background-color: #fde047;
    border-radius: 2vw;
    padding: 4vw 2vw;
    font-size: 6vw;
    color: rgb(161 98 7);
}

.static p {
    margin-top: 3vw;
    font-weight: 500;
    /* color: #6b7280; */
    color: #334155;
}

.static ul {
    list-style-type: disc;
    padding: 6vw 6vw;
    background-color: #f3f4f6;
    border-radius: 4vw;
    padding-inline-start: 10vw;
    margin-top: 6vw;
    margin-bottom: 6vw;

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.static.install ul {
    padding: 0 6vw;
    background-color: transparent;
    margin-top: 4vw;
    margin-bottom: 10vw;
    padding-inline-start: 4vw;
    gap: 2.5vw;
}

.static.install ul:last-child {
    margin-bottom: 0;
}

.static li {
    font-weight: 500;
    color: #334155;
}

.static hr {
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 10px;
    height: 10px;
    background-color: rgb(253 224 71);
    border-radius: 999px;
    border: 0;
}

.static strong {
    font-weight: 800;
}
.static u {
    /* text-decoration: underline 4px rgb(0 0 225); */
    text-decoration: none;
    transform: translateY(-3px);
    box-shadow: inset 0 -2px 0 0 rgb(253 224 71), 0 2px 0 0 rgb(253 224 71);
}

.static ol {
    list-style-type: decimal;
    padding: 6vw 6vw;
    background-color: #f3f4f6;
    border-radius: 4vw;
    padding-inline-start: 10vw;
    margin-top: 6vw;
    margin-bottom: 6vw;
    counter-reset: item;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.static ul > li {
    padding-inline-start: 2vw;
}

.static.install ul > li {
    padding-inline-start: 1vw;
}

.static ol > li {
    margin: 0;
    /* padding: 0 0 0 6vw; */
    padding-inline-start: 2vw;
    text-indent: -6vw;
    list-style-type: none;
    counter-increment: item;
}

.static ol > li:before {
    display: inline-block;
    width: 6vw;
    padding: 0vw;
    font-weight: 700;
    text-align: center;
    content: counter(item) '.';
}

.static.logs h1 {
    margin-bottom: 7vw;
}

.static h1:first-child {
    margin-top: 5vw;
}

.static.logs h2 {
    font-size: 7vw;
    /* margin-top: 10vw; */
}

.static.logs h2 {
    font-size: 6vw;
    /* margin-top: 10vw; */
}
.image-style-align-center {
    margin: auto;
    margin-bottom: 7vw;
}

.static figure.image {
    width: 100%;
    margin-bottom: 10vw;
}

.static figure.image img {
    width: 100%;
    box-shadow: 0 25px 50px -12px rgb(0 0 180 / 0.25);
    border-radius: 3vw;
}
