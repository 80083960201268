.messaging.str-chat .str-chat__list {
    padding: 0 0 6vw;
    background: white;
    /* background: #f3f4f6; */
}

.str-chat__message--me .str-chat__message-text-inner,
.str-chat__message-simple--me .str-chat__message-text-inner {
    flex: initial;
    /* background: var(--grey-whisper); */
    background: #eeeeff;
    border-color: transparent;
    text-align: right;
    border-radius: var(--border-radius) var(--border-radius) calc(var(--border-radius-sm) / 2) var(--border-radius);
    margin-right: 0;
    /* border: 1px solid rgba(0, 0, 255, 0.1); */
}

/* .str-chat__message-text-inner,
.str-chat__message-simple-text-inner {
    position: relative;
    flex: 1 1;
    display: block;
    min-height: 32px;
    padding: 2.5vw 3.5vw;
    font-size: 4.2vw;
    font-weight: 500;
    color: var(--black);
    border-radius: var(--border-radius) var(--border-radius)
        var(--border-radius) 0;
    background: #f3f4f6;
    border: none;
    margin-left: 0;
} */

.str-chat__message-text-inner,
.str-chat__message-simple-text-inner {
    position: relative;
    flex: 1 1;
    display: block;
    min-height: unset;
    padding: unset;
    font-size: unset;
    font-weight: unset;
    color: unset;
    border-radius: unset;
    background: unset;
    border: none;
    margin-left: unset;
    max-width: 64vw;
}

.str-chat__message p,
.str-chat__message-simple p {
    margin: 0;
    white-space: pre-line;
    line-height: 6vw;
}

.str-chat__message-text-inner--is-emoji,
.str-chat__message-simple-text-inner--is-emoji {
    font-size: var(--xxxl-font) !important;
}
