.str-chat__messaging-input {
    /* background: #ffffff; */
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    padding: 8px;
    position: relative;
}

.str-chat.dark .str-chat__messaging-input {
    background: #2e3033;
    border-top: 1px solid rgba(0, 0, 0, 0.16);
}

.messaging-input__input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 40px;
    height: fit-content;
    z-index: 100;
    border-radius: 20px;
    background: #ffffff;
    border: 2px solid rgba(0, 0, 0, 0.16);
    transition: border 0.2s ease-in-out;
}

.str-chat.dark .messaging-input__input-wrapper {
    border: 2px solid rgba(0, 0, 0, 0.16);
    background: #323437;
}

.str-chat__messaging-input .messaging-input__input-wrapper:focus-within {
    border: 2px solid #005fff;
}

.str-chat__messaging-input > *:not(:first-of-type) {
    margin-left: 8px;
}

.str-chat__textarea {
    display: flex;
    align-items: center;
}

.str-chat__textarea textarea {
    background: white;
    font-size: 4.2vw;
    font-weight: 500;
    padding: 2.2vw 4vw 2.2vw 0;
    line-height: 6vw;
    /* min-height: 0px; */
    /* transition: box-shadow 0.2s ease-in-out; */
    /* color: rgba(0, 0, 0, 0.9); */
    border: none !important;
    outline: none !important;
    border-radius: 0px;
    /* display: flex;
    align-items: center; */
    min-height: unset;
}

/* .str-chat__textarea textarea {
    width: 100%;
    outline: none;
    padding: var(--sm-p);
    background: var(--white);
    border: 1px solid var(--border);
    border-radius: var(--border-radius-md);
    font-size: var(--lg-font);
    min-height: 42px;
    transition: height 100ms ease-in;
    resize: none;
} */

.str-chat.dark .str-chat__messaging-input .str-chat__textarea textarea {
    background: #323437;
    border: 1px solid rgba(0, 0, 0, 0.16);
    color: rgba(255, 255, 255, 0.9);
}

.str-chat__textarea textarea:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.messaging-input__button {
    opacity: 0.5;
    cursor: pointer;
    padding: 0 4px;
    transition: opacity 0.2s ease-in-out;
}

.messaging-input__button svg path {
    transition: fill 0.2s ease-in-out;
    fill: black;
}

.messaging-input__button:hover {
    opacity: 1;
}

.messaging-input__button:hover svg path {
    fill: #005fff !important;
}

.str-chat__input--emojipicker {
    z-index: 100;
    left: 36px;
}

.str-chat__thread .str-chat__input--emojipicker {
    position: fixed;
    top: 25% !important;
    right: 330px;
    left: auto;
}

.str-chat__messaging-input .emoji-mart-bar,
.str-chat__messaging-input .emoji-mart-search input {
    border-color: rgba(0, 0, 0, 0.36);
}

.str-chat.dark .str-chat__messaging-input .messaging-input__button svg path {
    fill: white;
}

.giphy-icon__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 24px;
    width: 63px;
    background: #005fff;
    border-radius: 12px;
    margin-left: 8px;
}

.giphy-icon__text {
    font-family: Helvetica Neue, sans-serif;
    font-weight: bold;
    font-size: 11px;
    line-height: 8px;
    color: #ffffff;
}

div.rfu-dropzone {
    width: 100%;
}

div.rfu-dropzone:focus {
    outline: none;
}

.rfu-image-previewer {
    flex: none;
    margin-left: 12px;
}

.rfu-image-previewer__image {
    margin-bottom: 0;
}

div.rta__autocomplete.str-chat__emojisearch {
    z-index: 10;
    position: absolute;
    width: 30%;
    background: #fafafa;
    margin: 4px 10px;
    border-radius: 16px !important;
}

.str-chat__user-item {
    background: #ffffff;
    color: #000000;
}

.str-chat.dark .str-chat__user-item {
    background: rgba(46, 48, 51, 0.98);
    color: #ffffff;
}

.str-chat__user-item:hover {
    background: #005fff !important;
    color: #ffffff;
    cursor: pointer;
}

.rta__entity--selected {
    background: #005fff;
}

.str-chat__slash-command:hover {
    background: #005fff;
    cursor: pointer;
}

.rta__list-header {
    font-family: Helvetica Neue, sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.9);
    mix-blend-mode: normal;
}

@media screen and (max-width: 640px) {
    div.rta__autocomplete.str-chat__emojisearch {
        width: unset;
    }

    .str-chat__textarea textarea {
        font-size: 4.2vw;
    }
}

@media screen and (min-width: 640px) {
    .str-chat__textarea textarea {
        background: white;
        font-size: 20px;
        font-weight: 500;
        padding: 10px 20px 10px 0;
        line-height: 30px;
        /* min-height: 0px; */
        /* transition: box-shadow 0.2s ease-in-out; */
        /* color: rgba(0, 0, 0, 0.9); */
        border: none !important;
        outline: none !important;
        border-radius: 0px;
        min-height: unset;
    }
}

/* .str-chat__textarea textarea {
    width: 100%;
    outline: none;
    padding: var(--sm-p);
    background: var(--white);
    border: 1px solid var(--border);
    border-radius: var(--border-radius-md);
    font-size: var(--lg-font);
    min-height: 42px;
    transition: height 100ms ease-in;
    resize: none;
} */
